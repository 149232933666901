import React from "react"
import Layout from "../components/layout"

import { Seo } from "../components/seo"
import netcup from "../assets/images/netcup_logo_RGB_color.svg"
import greenElectricity from "../assets/images/netcup-oekostrom_en2.png"

import * as mainStyles from "../styles/index.module.scss"

export const Head = ({ location }) => (
  <Seo title="Powered by Netcup" pathname={location.pathname} />
)

const Netcup = ({ data }) => (
  <Layout>
    <h1>
      <img src={netcup} alt="Netcup" className={mainStyles.netcupLogo} />
    </h1>
    <p>
      <img src={greenElectricity} className={mainStyles.floatImage} />
      Brochain partners with Netcup for server resources. Netcup is a EU server
      provider based in Karlsruhe, Germany. They use 100% green electricity and
      focus om energy efficient hardware.
    </p>
    <p>
      If you are interested in their services, please check their lineup of
      services here:{" "}
      <a href="https://www.netcup.eu/vserver/">
        https://www.netcup.eu/vserver/
      </a>
    </p>
  </Layout>
)

export default Netcup
